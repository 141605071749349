import '../node_modules/@geckolabs/gecko-react-ui/lib/css';

import './App.css';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
//import registerServiceWorker from './registerServiceWorker';

import { Gecko } from './services';
import { router, $state } from './router/';

// Boot app
const bootApp = () => {
	//registerServiceWorker();
    ReactDOM.render(<App />, document.getElementById('root'));
    // Remove init loading (on first load)
    //if (window.removeLoading) setTimeout(() => window.removeLoading(), 500);
};

bootApp();
