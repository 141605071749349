import React, { Component } from 'react';
import { __, STR_KEY } from '../../services/Translate';
import './auth-text.css';

class AuthText extends Component {

    renderText() {
        return (
            <p> 
                {__(STR_KEY.AUTH_ALERT_START)} <strong className="auth-text-strong">{this.props.contactDetails.to}</strong> {__(STR_KEY.MISC_FROM)} <strong className="auth-text-strong">{this.props.contactDetails.from}</strong>. <br />
                {__(STR_KEY.AUTH_ALERT_END)}
            </p>
        );
    }

    render() {
        return (
            <div className="auth-text">
                {this.renderText()}
            </div>
        )
    }

}

export default AuthText;