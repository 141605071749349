import React, { Component } from 'react';

import { $stateParams, $state } from '../../router';

import { AppHeader, AppFooter, PortalMessage } from '../../components';

export class ErrorPage extends Component {

    render() {

        const error = this.props.resolves && this.props.resolves.error ? this.props.resolves.error : undefined;

        return (
            <div> 
                <AppHeader />

                <PortalMessage message={error}/>

                <AppFooter />
            </div>
        );
    }

}

export default ErrorPage;