import ErrorPage from './error';
import { Gecko, PortalService } from '../../services';
import { $stateParams, $state } from '../../router';

export default {
    name: 'error',
    url: '/error',
    component: ErrorPage, 
    resolve: [
        {
            token: 'error',
            deps: ['$transition$'],
            resolveFn: (trans) => {
                return trans.params().message;
            }
        }
    ], 
    params: {
        message: null
    }
};