import React, { Component } from 'react';
import { UIRouter, UIView } from '@uirouter/react';
import router from './router/router';

import { GeckoGlobal } from '@geckolabs/gecko-react-ui';
import AppHeader from './components/app-header/app-header';

class App extends Component {

    render() {
        return (
            <div>

                <UIRouter router={router}>

                    <div className="app-body">

                        <GeckoGlobal/>

                        <UIView/>

                    </div>

                </UIRouter>

            </div>
        );
    }
}

export default App;