import React, { Component } from 'react';
import { $stateParams, $state } from '../../router';

import {
    GECKO_CONSTS, 
    GECKO_UTILS,
	GeckoCardForm,
    GeckoDialogService,
    GeckoToastService, 
    GeckoLoadingService
} from '@geckolabs/gecko-react-ui';

import { 
    ProfileField, 
    ProfileBtn, 
    ProfileFields, 
    ProfileEditFields 
} from '../../components';

import { PortalService, FieldParser, Utils } from '../../services';
import { __, STR_KEY } from '../../services/Translate';

import './profile.css';

export class Profile extends Component { 

    state = {
        editing: false, 
        showingMore: false,
    };

    componentWillReceiveProps(nextProps) {
        this.setState({contact: nextProps.contact});
    }

    componentWillMount() {
        this.prepareValues(this.props.contact);
    }

    prepareValues(contact) {
        const values = contact.current_values.filter(value => this.filterValues(value));
        const data = new FieldParser().getEditFields(values);
        this.setState(Object.assign(data, {contact: contact}));
    }

    saveContact = () => {

        Utils.ga('Contact Fields', 'Save Changes');

        let values = this.state.values;
        // for(let f in values) {
        //     if(!values[f]) values[f] = null;
        // }

        new PortalService().saveFields(values).then(data => {
            new GeckoToastService().success(__(STR_KEY.PROFILE_TOAST_SAVE));
            // Update contact
            this.setState({contact: data.contact}, () => this.setState({editing: false}));
        }).catch(err => {
            new GeckoToastService().error(err);
        });
    }


    deleteContact = () => {
        new GeckoDialogService().confirm(__(STR_KEY.PROFILE_DIALOG_DELETE_CONTENT), {ariaLabel: __(STR_KEY.PROFILE_DIALOG_DELETE_CONTENT)}).then(confirm => {
            if (confirm) {
                // Show loading indicator
                new GeckoLoadingService().show();
                return new PortalService().deleteContact(this.props.contact.id).then(data => {
                    // Navigate to confirmation page
                    return $state.go('delete-confirmation');
                }).catch(err => {
                    // Hide loading indicator
                    new GeckoToastService().error(err);
                });
            }
        });
    }

    getFooterBtns() {
        return [
            {
                name     : __(STR_KEY.PROFILE_BTN_SAVE), 
                type      : 'primary',
                icon      : 'save',
                handler  : this.saveContact,
                primary  : true,
                hideWhen : () => !this.state.editing
            }, 
            {
                name      : __(STR_KEY.PROFILE_BTN_EDIT),
                icon      : 'edit',
                className : 'gecko-btn-edit',
                handler   : () => {this.setState({editing: true})}, 
                primary   : true,
                hideWhen  : () => this.state.editing
            },
            {
                name      : __(STR_KEY.PROFILE_BTN_CANCEL), 
                preset    : GECKO_CONSTS.BTN_PRESET_CLOSE,
                handler   : () => {this.setState({editing: false})},
                secondary : true,
                hideWhen  : () => !this.state.editing
            },
            {
                name      : __(STR_KEY.PROFILE_BTN_DELETE),
                icon      : 'trash-alt',
                className : 'gecko-btn-delete',
                secondary : true,
                hideWhen  : () => this.state.editing,
                handler   : this.deleteContact
            }
        ];
    }

    getHeaderBtns = () => {
        return [
        {
            icon: this.state.showingMore ? 'compress' : 'expand', 
            handler: () => { this.setState({showingMore: !this.state.showingMore })},
            class: 'profile-edit-btn',
            hideWhen: () => this.state.editing || !(this.state.values && Object.keys(this.state.values).length > 6)
        }
    ]}

    filterValues(value) {
        return value.field 
            && !value.field.is_calculated 
            && value.field.type !== 'consent'
            && value.field.portal_show;
    }

    renderProfileCard() {
        let fields = [];
        const values = this.state.contact.current_values.filter(value => this.filterValues(value)).map(val => {
            fields.push(val.field);
            return val;
        });

        return (
            <ProfileFields 
                key="profile-fields" 
                fields={fields} 
                values={this.state.showingMore ? values : values.slice(0,6)}/>
        );
    }

    renderProfileEditCard() {
        const values = this.state.contact.current_values.filter(value => this.filterValues(value));
        return (
            <ProfileEditFields 
                key="profile-edit-fields" 
                values={this.state.values} 
                fields={this.state.fields} 
                handleChange={this.handleChange}
                submitHandler={this.saveContact.bind(this)} />
        );
    }


    handleChange = (key, val) => {
        const newValues = GECKO_UTILS.setNestedValue(this.state.values, key, val);
        this.setState({values: newValues})
    }

    render() {
        return (
            <GeckoCardForm name={__(STR_KEY.PROFILE_TITLE)} headerBtns={this.getHeaderBtns()} footerBtns={this.getFooterBtns()}>
                {this.state.editing ? this.renderProfileEditCard() : this.renderProfileCard()}
            </GeckoCardForm>
        );
    }
} 


export default Profile;