declare var ga;

const Utils = {};

// Send Google Analytics Events
Utils.ga = (label, action) => {
	try {
		return ga('send', 'event', {
			eventCategory: 'RSVP_Page',
    		eventAction: action,
    		eventLabel: label
		});
	} catch (e) {}
}

export default Utils;