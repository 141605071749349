import React, { Component } from 'react';

export class ProfileField extends Component { 


    render() {
        return (
            <div className="profile-field">
                <label>{this.props.label}</label>
                <div>{this.props.value}</div>
            </div>
        );
    }
}

export default ProfileField;
