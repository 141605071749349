import React, { Component } from 'react';
import { Gecko, PortalService, Utils } from '../../services';

import { 
    GECKO_CONSTS, 
    GeckoCardForm, 
    GeckoCard, 
    GeckoCardBody, 
    GeckoLoadingService
} from '@geckolabs/gecko-react-ui';

import { PanelOptions, AuthText, AppHeader, AppFooter, PortalMessage } from '../../components';
import { __, STR_KEY } from '../../services/Translate';

import './auth.css';

export class AuthPage extends Component {

    state = {
        auth_method: 'email',
        available_auth_methods: this.props.resolves && this.props.resolves.contact && this.props.resolves.contact.available_auth_methods ? this.props.resolves.contact.available_auth_methods : [],
        code: null,
        success: false,
        error_msg: null, 
        success_msg: null,
    };

    handleChange(name, evt) {
        this.setState({[name]: evt});
    }

    portalService = new PortalService();

    sendEmail = () => {
        new GeckoLoadingService().show();
        this.portalService.sendAuthEmail().then(res => {
            new GeckoLoadingService().hide();
            this.setState({success_msg: res.message})
        }).catch(error => {
            new GeckoLoadingService().hide();
            this.setState({error_msg: error.message});
        });
    }

    getOptions = () => {
        return [
            {
                label: __(STR_KEY.AUTH_OPTION_EMAIL), 
                key: 'email',
                icon: 'envelope',
                disabled: !this.state.available_auth_methods.find(method => method.type === 'email'),
            }, 
            {
                label: __(STR_KEY.AUTH_OPTION_SMS), 
                key: 'sms',
                icon: 'mobile',
                disabled: true,// change this once we allow SMS auth: !this.state.available_auth_methods.find(method => method.type === 'sms'),
            }
        ] 
    };

    getContactDetails = () => {
        return this.state.available_auth_methods.reduce((values, method) => {
            values[method.type] = {to: method.value, from: method.from};
            return values;
        }, {});
    }

    footerBtns = [{
        name: __(STR_KEY.AUTH_BTN_SEND),
        icon: 'check',
        className: 'send-link-btn',
        preset: GECKO_CONSTS.BTN_PRESET_LOGIN,
        handler: () => {
            Utils.ga('Auth', 'Send Email');
            this.sendEmail();
        },
    }];

    onPanelOptionClick = (method) => {
        this.setState({auth_method: method});
    }

    renderOptionsCard() {
        return (
            <div className="options-card">

                <GeckoCardForm
                    name={__(STR_KEY.AUTH_TITLE)}
                    footerBtns={this.footerBtns}
                    handler={this.handleChange.bind(this)}>
                    <PanelOptions 
                        value={this.state.auth_method}
                        options={this.getOptions()} 
                        onPanelOptionClick={this.onPanelOptionClick}/> 
                    <AuthText
                        contactDetails={this.getContactDetails()[this.state.auth_method]}
                        type={this.state.auth_method} />
                </GeckoCardForm>
            </div>
        )
    }

    attemptAgain = () => {
        
        Utils.ga('Auth', 'Resend Email');

        this.sendEmail();
    }

    renderResponseCard() {
        if (this.state.error_msg) {
            return (
                <PortalMessage
                    message={this.state.error_msg}
                    type="error"
                    iconClass="fa fa-ban" /> 
            )
        }
        if (this.state.success_msg) {
            return (
                <PortalMessage
                    message={this.state.success_msg}
                    type="success"
                    iconClass="fa fa-check-circle" >
                
                    <p className="attempt-again-text">{__(STR_KEY.AUTH_ALERT_RESEND)} <strong className="attempt-again-btn" onClick={this.attemptAgain}>{__(STR_KEY.AUTH_BTN_RESEND)}</strong>.</p>

                </PortalMessage> 
            )  
        }
    }

    render() {
        return (
            <div className="auth-wrapper">
                <AppHeader />

                <GeckoCard> 
                    <GeckoCardBody> 
                        <div className="header">{__(STR_KEY.AUTH_ALERT_SECURITY)}</div>
                    </GeckoCardBody> 
                </GeckoCard>
                {this.state.success_msg || this.state.error_msg ? this.renderResponseCard() : this.renderOptionsCard()}
                <AppFooter />
            </div>
        );
    }
}

export default AuthPage;