import UnsubscribePage from './unsubscribe';
import { PortalService } from '../../services';
import { $state } from '../../router';

export default {
    name: 'unsubscribe',
    url: '/unsubscribe/:account_uuid/:uuid/:consents/:key',
    component: UnsubscribePage,
    resolve: [
        {
            token: 'unsubscribe',
            deps: ['$transition$'],
            resolveFn: (trans) => {
                const { uuid, account_uuid, consents, key } = trans.params();
                return new PortalService().unsubscribeFromEmail(account_uuid, uuid, consents, key).catch((message) => $state.go('error', message));
            }
        }
    ]
};