import React, { Component } from 'react';

import { GeckoCard, GeckoCardBody } from '@geckolabs/gecko-react-ui';

import PropTypes from 'prop-types';

import './portal-message.css';

export class PortalMessage extends Component {

    render() {

        return (
            <GeckoCard > 
                <GeckoCardBody>
                    <div className="message-container"> 
                        <div className={`${this.props.type}-icon`}> 
                            <i className={this.props.iconClass}> </i>
                        </div>
                        <p className="message-text">{this.props.message}</p>

                        {this.props.children}
                    </div>

                </GeckoCardBody>
            </GeckoCard>
        );
    }

}

PortalMessage.propTypes = {
    message: PropTypes.string,
    iconClass: PropTypes.string,
    type: PropTypes.oneOf(['success', 'error']),
}

PortalMessage.defaultProps = {
    type: 'error', 
    iconClass: 'fa fa-ban', 
    message: 'Sorry, something went wrong.',
  };

export default PortalMessage;