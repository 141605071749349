import router from './index';
import { GeckoLoadingService } from '@geckolabs/gecko-react-ui';
import { $state } from '../router';

router.transitionService.onBefore(true, (trans) => {
    console.log('Nav Start', trans.params());
    // Show loading indicator
    setTimeout(() => new GeckoLoadingService().show());
});

router.transitionService.onSuccess(true, (trans) => {
    console.log('Nav End');

    // Scroll to the top of the page
    window.scrollTo(0, 0);
    // Remove init loading (on first load)
    if (window.removeLoading) setTimeout(() => window.removeLoading(), 500);
    // Hide loading indicator
    setTimeout(() => new GeckoLoadingService().hide());
});

router.transitionService.onError(true, (err) => {
    console.log('Nav Error', err);
    // Remove init loading (on first load)
    if (window.removeLoading) setTimeout(() => window.removeLoading(), 500);
    // Hide loading indicator
    setTimeout(() => new GeckoLoadingService().hide());
});

export default router;