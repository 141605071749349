import { UIRouterReact } from '@uirouter/react';

const router     = new UIRouterReact();
let $state       = router.stateService;
let $stateParams = router.globals.params;

export {
    router,
    $state,
    $stateParams
};

export default router;