import PortalPage from './portal';
import { Gecko, PortalService } from '../../services';
import { $stateParams, $state } from '../../router';

export default {
    name: 'portal',
    url: '/portal/:account_uuid/:uuid/:key',
    component: PortalPage,
    resolve: [
        {
            token: 'data',
            deps: ['$transition$'],
            resolveFn: (trans) => {
                const uuid = trans.params().uuid;
                const account_uuid = trans.params().account_uuid;
                const key = trans.params().key;

                return new PortalService().getContactData(uuid, account_uuid, key).catch(() => $state.go('auth', {account_uuid: account_uuid, uuid: uuid}));
            }
        },
        {   
            token: 'contact',
            deps: ['data'],
            resolveFn: (data) => {
                return data.contact ? data.contact : {};

            }
        },
        {   
            token: 'consents',
            deps: ['data'],
            resolveFn: (data) => {
                return data.consents;
            }
        }
    ],
    params: {
        account_uuid: {
          dynamic: true
        //   squash: true // it its an optional parameter
        }, 
        uuid: {
            dynamic: true,
        }, 
        key: {
            dynamic: true,
            // squash: true // it its an optional parameter

        }
    }
};