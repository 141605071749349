import React, { Component } from 'react';

import { GeckoFields } from '@geckolabs/gecko-react-ui';

import { ProfileField, ProfileBtn } from '../../components';

import { PortalService, FieldParser } from '../../services';

import './profile.css';

export class ProfileEditFields extends Component { 

    handleChange = (key, value) => {
        this.props.handleChange(key, value);
    }

    render() {
        return (
            <GeckoFields 
                fields={this.props.fields} 
                values={this.props.values} 
                handler={this.handleChange}
                submitHandler={this.props.submitHandler.bind(this)} /> 
        )
    }
} 


export default ProfileEditFields;