import DeleteConfirmationPage from './delete-confirmation';
import { Gecko, PortalService } from '../../services';
import { $stateParams, $state } from '../../router';

export default {
    name: 'delete-confirmation',
    url: '/delete-confirmation',
    component: DeleteConfirmationPage, 
    // resolve: [
    //     {
    //         token: 'error',
    //         deps: ['$transition$'],
    //         resolveFn: (trans) => {
    //             return trans.params().message;
    //         }
    //     }
    // ]
};