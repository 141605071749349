import './delete-confirmation.css';
import React, { Component } from 'react';
import { GeckoCard, GeckoAlert } from '@geckolabs/gecko-react-ui';

export class DeleteConfirmationPage extends Component {

    render() {
        return (
            <div className="delete-confirmation"> 
                <GeckoCard padded={true}>
                	<GeckoAlert>
                		Your delete request has been received. This may take up to 15 days to execute.
                	</GeckoAlert>
                </GeckoCard>
            </div>
        );
    }

}

export default DeleteConfirmationPage;