import './404.css';
import React, { Component } from 'react';

export class FourOFourPage extends Component {

    render() {
        return (
            <div className="four-o-four-wrapper">
                <div className="four-o-four-content">
                    <div className="image">
                        <img 
                            src="https://images.geckoform.com/gecko-404.svg" 
                            alt="Gecko-404" />
                    </div>
                    <h2>404</h2>
                    <p>Sorry, we're having trouble trying to find what you're looking for.</p>
                </div>
            </div>
        );
    }
    
}

export default FourOFourPage;