import router from './index';

// States
import fourofour from '../modules/404/state';
import auth from '../modules/auth/state';
import portal from '../modules/portal/state';
import error from '../modules/error/state';
import deleteconfirmation from '../modules/delete-confirmation/state';
import unsubscribe from '../modules/unsubscribe/state';

const states = [
    fourofour,
    auth,
    portal, 
    error,
    deleteconfirmation,
    unsubscribe
];

// Register states
states.forEach(state => router.stateRegistry.register(state));

export default router;