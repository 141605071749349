import { $stateParams } from '../../router';
import PanelOption from './panel-option';

import React, { Component } from 'react';

class PanelOptions extends Component {

    getPanelOptions() {
        return this.props.options ? this.props.options.map(option => {
            return (
                <PanelOption
                    option={option}
                    disabled={option.disabled}
                    onPanelOptionClick={() => this.props.onPanelOptionClick(option.key)}
                    key={option.key}
                    active={this.props.value === option.key}>
                </PanelOption>
            );
        }) : null;
    }

    render() {
        return (
            <div className="panel-options">
                {this.getPanelOptions()}
            </div>
        )
    }

}

export default PanelOptions;