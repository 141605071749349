import React, { Component } from 'react';

import { } from '../../components';

import './consent-fields.css';

export class ConsentTableRow extends Component {

    constructor(props) {
        super(props);

        this.labelRef = React.createRef();
    }    

    renderClassName = () => {
        let className = 'consent-row';
        if (this.props.row.granted) className += ' active';
        return className;
    }

    renderTitle = () => {
        return <span className="consent-title" >{this.props.row.title}</span>
    }

    renderDescription = () => {
        return <span className="consent-description" >{this.props.row.description}</span>
    }

    handleChange = (event) => {
        // Set falsy to NULL!
        const value = event.target.checked || null;
        return this.props.changeConsent(this.props.row.id, value);
    }

    render() {
        return (
            <li className={this.renderClassName()} onClick={() => this.labelRef.current.click()}> 
                <div>
                    <div className="consent-reason-checkbox">
                        <input 
                            id={`consent-reason-${this.props.row.id}`}
                            type="checkbox"
                            checked={this.props.row.granted || false} 
                            onChange={this.handleChange.bind(this)} />
                        <div>&nbsp;</div>
                    </div>
                    <div className="consent-reason-meta">
                        <label ref={this.labelRef} htmlFor={`consent-reason-${this.props.row.id}`} className="field-card-header-text consent-reason-title">
                            {this.renderTitle()}
                        </label>
                        <div className="field-card-description-text consent-reason-description" ng-if="reason.description">
                            {this.renderDescription()}
                        </div>
                    </div>
                </div>
            </li>
        );
    }

}

export default ConsentTableRow;