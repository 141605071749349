import React, { Component } from 'react';

import {
    GeckoRow,
    GeckoCol
} from '@geckolabs/gecko-react-ui';

import { ProfileField } from '../../components';

import './profile.css';

import { FieldParser } from '../../services';

export class ProfileFields extends Component { 

    renderFields() {
        const fields = new FieldParser().getProfileFields(this.props.values);
        return fields.map((field, i) => {
            return (
                <GeckoCol width="sm-6" key={i}>
                    <ProfileField label={field.label} value={field.value}/>
                </GeckoCol>
            );
        });
    }

    render() {
        return (
            <GeckoRow>
                {this.renderFields()}
            </GeckoRow>
        )
    }

} 


export default ProfileFields;