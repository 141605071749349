import './file-field.css';
import React, { Component } from 'react';

export class FileField extends Component {

	render() {
		if (!this.props.files || !this.props.files.length) return '-';

		return (
			<ul className="file-field">
				{this.props.files.map((f, i) => {
				    return (
				        <li key={i}>
				        	<a href={f} target="_blank">
				            	{f}
				        	</a>
				        </li>
				    );
				})}
			</ul>
		);
	}

}

export default FileField;



