let LOCALE;
let TRANSLATION_JSON;

export const STR_KEY = {};

STR_KEY.MISC_POWERED_BY          = 'misc.powered_by';
STR_KEY.MISC_ALERT_AUTHENTICATED = 'misc.alert_authenticated';
STR_KEY.MISC_FROM                = 'misc.from';

STR_KEY.AUTH_TITLE          = 'auth.title';
STR_KEY.AUTH_OPTION_EMAIL   = 'auth.option_email';
STR_KEY.AUTH_OPTION_SMS     = 'auth.option_sms';
STR_KEY.AUTH_BTN_SEND       = 'auth.btn_send';
STR_KEY.AUTH_ALERT_SECURITY = 'auth.alert_security';
STR_KEY.AUTH_ALERT_START    = 'auth.alert_confirmation_start';
STR_KEY.AUTH_ALERT_END      = 'auth.alert_confirmation_end';
STR_KEY.AUTH_ALERT_RESEND   = 'auth.alert_resend';
STR_KEY.AUTH_BTN_RESEND     = 'auth.btn_resend';

STR_KEY.PROFILE_TITLE                 = 'profile.title';
STR_KEY.PROFILE_BTN_SAVE              = 'profile.btn_save';
STR_KEY.PROFILE_BTN_EDIT              = 'profile.btn_edit';
STR_KEY.PROFILE_BTN_CANCEL            = 'profile.btn_cancel';
STR_KEY.PROFILE_BTN_DELETE            = 'profile.btn_delete';
STR_KEY.PROFILE_TOAST_SAVE            = 'profile.toast_save';
STR_KEY.PROFILE_DIALOG_DELETE_CONTENT = 'profile.dialog_delete_content';

STR_KEY.CONSENTS_TITLE          = 'consents.title';
STR_KEY.CONSENTS_BTN_SAVE       = 'consents.btn_save';
STR_KEY.CONSENTS_BTN_ALL_ADD    = 'consents.btn_all_add';
STR_KEY.CONSENTS_BTN_ALL_REMOVE = 'consents.btn_all_remove';
STR_KEY.CONSENTS_BTN_PRIVACY    = 'consents.btn_privacy';
STR_KEY.CONSENTS_TOAST_SAVE     = 'consents.toast_save';
STR_KEY.CONSENTS_MODAL_TITLE    = 'consents.modal_title';

export const getLocale = () => LOCALE || 'en';

export const initTranslation = (key) => {
    // Only load once
    if (TRANSLATION_JSON) return Promise.resolve();
    // Set locale (for ref)
    LOCALE = key;
    
    return new Promise((resolve, reject) => {
        return fetch(`/translations/${getLocale()}.json`).then(res => {  
            return res.json().then(json => {
                TRANSLATION_JSON = json;
                resolve();
            }).catch((err) => console.log('Translation Error: ' + err));
        });
    });
};

export const __ = (key, fallback = '') => {
    // Return key if translation data is not found
    if (!TRANSLATION_JSON) return fallback || key;

    const string = String(key).split('.').reduce((obj, part) => obj[part], TRANSLATION_JSON);
    return typeof string === 'string' ? string : ''; 
};

export default __;