import React, { Component } from 'react';
import { 
    GECKO_CONSTS,
    GeckoModalService, 
    GeckoToastService,
    GeckoModal
} from '@geckolabs/gecko-react-ui';
import DOMPurify from 'dompurify';
import { ConsentField, ConsentFieldsTable } from '../../components';
import { PortalService, Utils } from '../../services';
import { __, STR_KEY } from '../../services/Translate';

import './consent-fields.css';

export class ConsentFields extends Component {

    state = {
        consent_data: this.props.contact.consent_data,
    }

    openPrivacyPolicy = () => {
        //Google Analytics event: 
        Utils.ga('Consents', 'See Privacy Policy');

        const cleaned = DOMPurify.sanitize(this.props.contact.account.privacy_policy || '', {
            ADD_ATTR: ['target']
        });

        return new GeckoModalService().open(() => {
            return (
                <GeckoModal name={__(STR_KEY.CONSENTS_MODAL_TITLE)}>
                    <div dangerouslySetInnerHTML={{__html: cleaned}}>
                    </div>
                </GeckoModal>
            );
        }, {ariaLabel: __(STR_KEY.CONSENTS_MODAL_TITLE)});
    }

    allSet(value) {
        return this.state.consent_data.every(item => item.granted);
    }

    getFooterBtns() {
        return [{
            type      : 'primary',
            icon      : 'save',
            name: __(STR_KEY.CONSENTS_BTN_SAVE), 
            handler: this.saveConsents.bind(this),
            primary: true,
        }, {
            name: __(STR_KEY.CONSENTS_BTN_ALL_ADD),
            icon: 'check',
            handler: this.consentToAll.bind(this),
            secondary: true,
            hideWhen: () => this.allSet.bind(this)(),
            className: 'gecko-btn-consent-to'
        }, {
            name: __(STR_KEY.CONSENTS_BTN_ALL_REMOVE),
            icon: 'ban',
            handler: this.removeAll.bind(this),
            secondary: true,
            hideWhen: () => !this.allSet.bind(this)(),
            className: 'gecko-btn-remove-all'
        }, {
            name: __(STR_KEY.CONSENTS_BTN_PRIVACY),
            icon: 'book',
            handler: this.openPrivacyPolicy,
            disabledWhen: () => !this.props.contact || !this.props.contact.account || !this.props.contact.account.privacy_policy,
            secondary: true,
            className: 'gecko-btn-privacy-policy'
        }]
    }

    consentToAll = () => {
        const consent_data = this.state.consent_data ? this.state.consent_data.map(reason => {
            return Object.assign(reason, {granted: true});
        }) : [];
        this.setState({consent_data: consent_data});
    }

    removeAll = () => {
        const consent_data = this.state.consent_data ? this.state.consent_data.map(reason => {
            return Object.assign(reason, {granted: null});
        }) : [];
        this.setState({consent_data: consent_data});
    }

    saveConsents() {
        //Google Analytics event: 
        Utils.ga('Consents', 'Save Changes');

        let contact = this.props.contact;
        contact.consent_data = this.state.consent_data;
        // Save consents
        return new PortalService().updateConsent(contact).then(contact => {
            new GeckoToastService().success(__(STR_KEY.CONSENTS_TOAST_SAVE));
        }).catch(err => new GeckoToastService().error(err.errors))
    }

    changeConsent = (id, value) => {
        let consent_data = this.state.consent_data;
        consent_data.find(c => c.consent === id).granted = value;
        // Update state
        this.setState({consent_data});

        this.forceUpdate();
    }

    renderRows() {
        return this.props.consents && this.props.consents.length ? this.props.consents.map(consent => {
            const reason = this.state.consent_data ? this.state.consent_data.find(r => r.consent === consent.id) : false;
            return Object.assign(consent, {granted: reason.granted});
        }) : [];
    }

    render() {
        return (
            <div className="consent-field-wrapper"> 
                <ConsentFieldsTable 
                    name={__(STR_KEY.CONSENTS_TITLE)}
                    footerBtns={this.getFooterBtns()}
                    rows={this.renderRows()}
                    changeConsent={this.changeConsent} >
                </ConsentFieldsTable>
            </div>
        );
    }

}

export default ConsentFields;