import './app-footer.css';
import React, { Component } from 'react';
import { __, STR_KEY } from '../../services/Translate';

export class AppFooter extends Component {
    render() {
    	return (
    		<div className="app-footer text-center">
                <p>
                    {__(STR_KEY.MISC_POWERED_BY, 'Powered by')} <a target="_blank" href="https://www.geckoengage.com"><strong>Gecko</strong></a>
                </p>
            </div>
    	);
    }
}

export default AppFooter;