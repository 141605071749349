const LANG_CATALAN = 'ca';
const LANG_WELSH = 'cy';
const LANG_ENGLISH = 'en';
const LANG_SPANISH = 'es';
const LANG_GERMAN = 'de';
const LANG_CHINESE = 'zh-cn';

const languages = [{
    value: LANG_CATALAN,
    label: `Catalan - ${LANG_CATALAN}`
}, {
    value: LANG_CHINESE,
    label: `Chinese - ${LANG_CHINESE}`
}, {
    value: LANG_ENGLISH,
    label: `English - ${LANG_ENGLISH}`
}, {
    value: LANG_GERMAN,
    label: `German - ${LANG_GERMAN}`
}, {
    value: LANG_SPANISH,
    label: `Spanish - ${LANG_SPANISH}`
}, {
    value: LANG_WELSH,
    label: `Welsh - ${LANG_WELSH}`
}];

export default languages;