import AuthPage from './auth';
import { Gecko, PortalService } from '../../services';
import { $stateParams, $state } from '../../router';

export default {
    name: 'auth',
    url: '/auth/:account_uuid/:uuid',
    component: AuthPage, 
    resolve: [
        {
            token: 'auth',
            deps: ['$transition$'],
            resolveFn: (trans) => {
                const uuid = trans.params().uuid;
                const account_uuid = trans.params().account_uuid;
                return new PortalService().getAuthData(uuid, account_uuid).catch((message) => $state.go('error', message));
            }
        }, 
        {
            token: 'contact',
            deps: ['auth'],
            resolveFn: (auth) => {
                if (!auth) return;
                return auth.contact;
            }
        }
    ], 
    params: {
        account_uuid: {
            dynamic: true,
        }, 
        uuid: {
            dynamic: true,
        }
    }
};