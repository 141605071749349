import React, { Component } from 'react';
import PropTypes from 'prop-types';
// Components
import { 
    GeckoCardWrapper,
    GeckoCardHeader,
    GeckoCardBody,
    GeckoCardFooter,
    GeckoBtnsSorted
} from '@geckolabs/gecko-react-ui';

import { ConsentTableRow } from '../../components';

class ConsentFieldsTable extends Component {

    renderHeader() {
        return <GeckoCardHeader name={this.props.name} btns={this.props.headerBtns} />
    }

    renderTable() {
        return (
            <GeckoCardBody>
                <ul className="consent-reasons gecko-table-wrapper">

                { this.renderRows() }
                </ul>               
            </GeckoCardBody> 
        );
    }

    renderBody() {
        if (this.props.rows && this.props.rows.length) {
            return this.renderTable();
        }
    }

    renderFooter() {
        return (
            <GeckoCardFooter>
                <GeckoBtnsSorted items={this.props.footerBtns} />
            </GeckoCardFooter>
        );
    }

    renderRows() {
        return this.props.rows.map((row, i) => <ConsentTableRow changeConsent={this.props.changeConsent} row={row} index={i} key={i} />);
    }

    render() {
        return (
            <GeckoCardWrapper>

                {this.renderHeader()}
                
                {this.renderBody()}

                {this.renderFooter()}
                
            </GeckoCardWrapper>          
        );
    }
}


// GeckoCardTable.defaultProps = {
//     headerBtns: [],
//     cols: [],
//     rows: [],
//     hideTableHeader: false,
//     rowHandler: () => {},
//     pagination: {}
// };

// GeckoCardTable.propTypes = {
//     headerBtns: GeckoBtnsModel,
//     cols: GeckoTableCellsModel.isRequired,
//     rows: PropTypes.array.isRequired,
//     hideTableHeader: PropTypes.bool,
//     rowHandler: PropTypes.func,
//     pagination: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape(GeckoPaginationModel)])
// };

export default ConsentFieldsTable;