import { $stateParams } from '../../router';

import React, { Component } from 'react';

import './panel-option.css'
class PanelOption extends Component {

    getClassName() {
        let className = 'panel-option';
        if (this.props.active) className += ' active';
        if (this.props.disabled) className += ' disabled';
        return className;
    }

    render() {
        return (
            <div 
                className={this.getClassName()} 
                onClick={this.props.disabled ? null : this.props.onPanelOptionClick }>
                    <span className="panel-option-icon" >
                        <i className={"fa fa-" + this.props.option.icon} ></i>
                    </span>
                    <span className="panel-option-title">
                        {this.props.option.label}
                    </span>
            </div>
        )
    }

}

export default PanelOption;