import React, { Component } from 'react';

import { AppHeader, PortalMessage } from '../../components';

export class UnsubscribePage extends Component {

    render() {

        const message = this.props.resolves && this.props.resolves.unsubscribe ? this.props.resolves.unsubscribe.message : 'You were unsubscribed.'; 

        return (
            <div> 
                <AppHeader title=" "/>

                <PortalMessage 
                    message={message} 
                    type="success" 
                    iconClass="fa fa-check-circle"/>
            </div>
        );
    }

}

export default UnsubscribePage;