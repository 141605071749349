import './app-header.css';
import React, { Component } from 'react';
import { Gecko } from '../../services';
import { router, $state, $stateParams } from '../../router/';
import { GECKO_UTILS } from '@geckolabs/gecko-react-ui';

export class AppHeader extends Component {


    account() {
        return this.props.account || {};
    }

    styles(){
        return {
            banner: {
                backgroundImage: `url(${this.account().header_bg})`, 
                backgroundSize: 'cover'
            },
            bannerMask: {
                background: this.account().color
            },
            logoContainer: {
                color: GECKO_UTILS.getContrastColor(this.account().color)
            }
        };
    }

    renderLogo() {
        const event = this.props.events && this.props.events.length ? this.props.events.find(e => !e.parent_id) : {};
        if (this.account().header_logo) return <img src={this.account().header_logo} className="logo" alt={event.title} />;
        return <img src={`${process.env.PUBLIC_URL}/images/gecko-logo-new.png`} className="logo" alt="Gecko logo" />;
    }

    render() {
        return (
            <div className="app-header text-center">
                <div className="logo-container" style={this.styles().logoContainer}>
                    <div>
                        {this.renderLogo()}
                        <h1 className="title">{this.props.title || "Contact Info Page"}</h1>
                    </div>
                </div>
                <div className="banner-container">
                    <div className="banner" style={this.styles().banner}/>
                    <div className="banner-mask" style={this.styles().bannerMask}/>
                </div>
            </div>
        );
    }

}

export default AppHeader;