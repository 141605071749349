import Gecko from '../gecko/gecko';
import { $stateParams, $state } from '../../router';
import Utils from '../utils/utils';
import { initTranslation } from '../Translate';

class PortalService {

    rfields = {
        contact: [
            'consent_data', 
            'preferred_language'
        ], 
        consent: [
            'title', 
            'description'
        ], 
        field: [
            'field_type',
            'type', 
            'tag', 
            'key', 
            'label', 
            'is_calculated', 
            'data_type', 
            'is_editable', 
            'contact_field_id',
            'order', 
            'portal_show',
            'values',
            'required'
        ], 
        option: [
            'values',
        ], 
        value: [
            // 'field', 
            'value',
            'safe'
        ]
    };

    includes = [
        'account', 
        'current_values:all' , 
        'current_values.field' ,
        'current_values.field.option', 
        'consents'
    ];


    getContactData(uuid, account_uuid, key) {
        Gecko.contact_auth_header = uuid + '-' + key;
        return new Gecko.Portal().include(this.includes).rfields(this.rfields).call('/portal/contact', 'GET', {}, true).then(data => {
            return initTranslation(data.contact.preferred_language).then(() => data);
        });
    }

    getAuthData(uuid, account_uuid) {
        Gecko.contact_auth_header = null;
        return new Gecko.Portal().call(`/portal/auth/${account_uuid}/${uuid}`, 'GET', {}, true).then(data => {
            return initTranslation(data.contact.preferred_language).then(() => data);
        });
    }

    sendAuthEmail() {
        Gecko.contact_auth_header = null;
        const uuid = $stateParams.uuid;
        const account_uuid = $stateParams.account_uuid;
        return new Gecko.Portal().call(`/portal/auth/${account_uuid}/${uuid}`, 'POST', {type: 'email'}, true);

    }

    updateConsent(contact) {

        Utils.ga('Consents', 'update');

        Gecko.contact_auth_header = $stateParams.uuid + '-' + $stateParams.key;
        return new Gecko.Portal().include(this.includes).rfields(this.rfields).call('/portal/contact/' + $stateParams.uuid, 'POST', {consent_data: contact.consent_data}, true);
    }

    saveFields(fields) {
        Gecko.contact_auth_header = $stateParams.uuid + '-' + $stateParams.key;
        return new Gecko.Portal().include(this.includes).rfields(this.rfields).call('/portal/contact/' + $stateParams.uuid, 'POST', {fields: fields}, true);
    }

    deleteContact(contact_id) {
        Gecko.contact_auth_header = $stateParams.uuid + '-' + $stateParams.key;
        return new Gecko.Portal().include(this.includes).rfields(this.rfields).call('/portal/contact/' + $stateParams.uuid, 'DELETE', {}, true);
    }

    unsubscribeFromEmail(account_uuid, uuid, consents, key) {
        Gecko.contact_auth_header = uuid + '-' + key;
        return new Gecko.Portal().call(`/portal/unsubscribe/${account_uuid}/${uuid}/${consents}`, 'POST', {}, true);
    }
    
}

export default PortalService;