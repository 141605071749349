import './portal.css';
import React, { Component } from 'react';

import { GeckoCard, GeckoAlert } from '@geckolabs/gecko-react-ui';
import { $stateParams, $state } from '../../router';

import { Profile, AppHeader, AppFooter, ConsentFields } from '../../components';
import { __, STR_KEY } from '../../services/Translate';

export class PortalPage extends Component {

    render() {

        return (
            <div className="portal-wrapper"> 
                <AppHeader account={this.props.resolves.contact.account}/>

                <GeckoCard padded> 
                    <GeckoAlert type="success">
                        {__(STR_KEY.MISC_ALERT_AUTHENTICATED)}
                    </GeckoAlert>
                </GeckoCard>

                <div role="main">
                    <Profile contact={this.props.resolves && this.props.resolves.contact ? this.props.resolves.contact : {}}/>
                    <ConsentFields consents={this.props.resolves ? this.props.resolves.consents.data : []} contact={this.props.resolves ? this.props.resolves.contact : {}}/>
                </div>

                <AppFooter />
            </div>
        );
    }

}

export default PortalPage;