import React from 'react';
import moment from 'moment';
import { GECKO_CONSTS } from '@geckolabs/gecko-react-ui';
import Gecko from '../gecko/gecko';
import FileField from '../../components/file-field/file-field';

import countries from './address-options';
import languages from './language-options';

class FieldParser {

    prepareOptions(field) {
        if (field && field.option && field.option.values) {
            return field.option.values.map(item => ({label: item.value, value: item.value}));
        }
        // legacy address fields don't have their own country options
        if (field.type === 'address') {
            return countries;
        }

        if (field.type === 'preferred_language' && Array.isArray(field.values)) {
            return field.values.map(value => ({value: value.value, label: languages.find(language => language.value === value.value).label}));
        }

        return [];
    }

    orderFields(fields) {
        return fields.sort(function (a, b) {
            if (a.order > b.order) { return 1; }
            if (a.order < b.order) { return -1; }
            return 0;
        });
    }

    // Simplified to use SAFE!
    getProfileFields(values) {
        let fields = values.map(value => {
            let val;

            if (value.field.type === 'date') {
                val = moment.unix(value.value).format("DD/MM/YYYY");
            } else if (value.field.type === 'file') {
                val = value.safe;
            } else if (value.field.type === 'preferred_language') {
                val = value.field.values ? languages.find(language => language.value === value.value).label || '' : '';
            } else {
                val = value.safe;
            }
            
            return {
                label : value.field.label,
                order : value.field.order,
                value : val,
                type  : value.field.type
            };
        });
        return this.orderFields(fields);
    }

    editType(type) {
        const allowedTypes = [
            GECKO_CONSTS.FIELD_TYPE_ADDRESS,
            GECKO_CONSTS.FIELD_TYPE_CHECKBOX,
            GECKO_CONSTS.FIELD_TYPE_COLOR,
            GECKO_CONSTS.FIELD_TYPE_CUSTOM,
            GECKO_CONSTS.FIELD_TYPE_DATE,
            GECKO_CONSTS.FIELD_TYPE_DISPLAY,
            GECKO_CONSTS.FIELD_TYPE_EMAIL,
            GECKO_CONSTS.FIELD_TYPE_NAME,
            GECKO_CONSTS.FIELD_TYPE_NUMBER,
            GECKO_CONSTS.FIELD_TYPE_PASSWORD,
            GECKO_CONSTS.FIELD_TYPE_RADIO,
            GECKO_CONSTS.FIELD_TYPE_RICH_TEXT,
            GECKO_CONSTS.FIELD_TYPE_SEARCH,
            GECKO_CONSTS.FIELD_TYPE_SELECT,
            GECKO_CONSTS.FIELD_TYPE_TELEPHONE,
            GECKO_CONSTS.FIELD_TYPE_TEXT,
            GECKO_CONSTS.FIELD_TYPE_TEXTAREA,
            GECKO_CONSTS.FIELD_TYPE_TITLE,
            GECKO_CONSTS.FIELD_TYPE_TOGGLE,
            GECKO_CONSTS.FIELD_TYPE_URL
        ];

        // Change field type if not allowed
        if (allowedTypes.indexOf(type) === -1) {

            if (type === Gecko.Field.TYPE_MULTI) return GECKO_CONSTS.FIELD_TYPE_SELECT_MULTI;

            if (type === Gecko.Field.TYPE_PREFERRED_LANGUAGE) return GECKO_CONSTS.FIELD_TYPE_SELECT;

            return GECKO_CONSTS.FIELD_TYPE_DISPLAY;
        };

        return type;
    }

    getEditFields(valuesData) {
        let values = {};
        let fields = [];
        valuesData.forEach(val => {
            const value = val.value;
            const field = val.field;
            const field_id = field.contact_field_id ? field.contact_field_id : field.id;
            const identifier = `field${field_id}`;

            // Check type / change field type
            let type = this.editType(field.type);
            
            let _field  = {
                key     : identifier, 
                type    : type,
                label   : field.label,
                order   : field.order,
                options : this.prepareOptions(field),
                required: !!field.required,
                width: '100%'
            };

            // Date
            if (field.type === Gecko.Field.TYPE_DATE) {
                _field.format = 'X';
                _field.display_format = 'DD/MM/YYYY';
            }

            // Email
            if (field.type === Gecko.Field.TYPE_EMAIL) {
                _field.autocomplete = 'email';
            }

            // Prefered Language
            if (field.type === Gecko.Field.TYPE_PREFERRED_LANGUAGE) {
                _field.config = {
                    clearable: false
                };
            }

            // Add value ref
            if (type === GECKO_CONSTS.FIELD_TYPE_DISPLAY) {

                //values[identifier] = val.safe;
                // THIS WILL CHANGE WHEN REACTUI IS UPDATED!!!
                if (field.type === 'file') {
                    _field.render = () => (<FileField files={val.value} />);
                } else {
                    _field.render = () => val.safe;
                }
            } else {
                values[identifier] = value; 
            }

            fields.push(_field);

        });

        return {fields: this.orderFields(fields), values: values};
    }

}

export default FieldParser;